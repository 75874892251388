(function () {
    if ('modules' in $ && 'formTextarea' in $.modules) {
        return;
    }

    var FormTextarea = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('focus', '.formTextarea:not(.formTextarea_disabled) textarea', self.focusIn);
            self.body.on('click', '.formTextarea:not(.formTextarea_disabled) label', self.focusLabel);
            self.body.on('blur', '.formTextarea:not(.formTextarea_disabled) textarea', self.focusOut);
            self.body.on('click focus blur input change', '.formTextarea.formTextarea_disabled', self.disabled);
        };

        self.focusIn = function(event) {
            $(this).closest('.formTextarea').addClass('active');
        };

        self.focusLabel = function(event) {
            $(this).closest('.formTextarea').find('textarea').focus();
        };

        self.focusOut = function(event) {
            if ($(this).val() === '') {
                $(this).closest('.formTextarea').removeClass('active');
            }
        };

        self.disabled = function(event) {
            event.preventDefault();
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formTextarea = new FormTextarea();
})();
