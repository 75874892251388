(function () {
    if ('modules' in $ && 'modal' in $.modules) {
        return;
    }

    var Modal = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('click', '.modal_trigger', self.router);
            self.body.on('click', '.modal_close', self.close);
        };

        self.router = function(event) {
            event.preventDefault();

            var target = $(this),
                src = '';

            if (target.hasClass('modal_trigger')) {

                src = target.attr('href');

                self.open(src);

            } else if (target.hasClass('modal_close')) {

                self.close();

            }
        };

        self.open = function(src) {
            $.magnificPopup.open({
                items: {
                    src: src,
                    type: 'inline'
                },
                callbacks: {
                    beforeOpen: function() {
                        self.body.addClass('modal_js_open');
                    },
                    close: function() {
                        self.body.removeClass('modal_js_open');
                    }
                },
                showCloseBtn: false,
                midClick: true
            });
        };

        self.close = function() {
            $.magnificPopup.close();
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.modal = new Modal();
})();
