(function () {
    if ('modules' in $ && 'informer' in $.modules) {
        return;
    }

    var Informer = function () {
        var self = this;

        self.body = '';
        self.close = '';

        self.setupListner = function() {
            self.body.on('click', '.informer__close', function(event) {
                event.preventDefault();

                self.close();
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.close = $.modules.modal.close;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.informer = new Informer();
})();
