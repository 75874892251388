(function () {
    if ('modules' in $ && 'review' in $.modules) {
        return;
    }

    var Review = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.container = $('.review');
        self.preview = $('.review__preview');
        self.title = $('.review__title');
        self.content = $('.review__content');
        self.trigger = $('.review_trigger');

        self.setupListner = function() {
            self.trigger.on('click', self.setData);
        };

        self.setData = function() {
            var target = $(this),
                container = target.closest('.reviews__container'),
                data = container.data('modal');

            self.preview.html(data.preview);
            self.title.html(data.title);
            self.content.html(data.content);
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.review = new Review();
})();
