(function () {
    if ('modules' in $ && 'header' in $.modules) {
        return;
    }

    var Header = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.container = $('.header');
        self.title = $('.header__title');
        self.logo = $('.header__logo');
        self.limit = 150;
        self.small = false;

        self.setupListner = function() {
            self.window.on('scroll', self.scroll);
        };

        self.scroll = function() {
            var top = self.window.scrollTop();

            if (top > self.limit) {

                self.container.addClass('js_small');

            } else {

                self.container.removeClass('js_small');

            }
        };

        self.copy = function() {
            self.title.clone().insertAfter(self.logo);
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.copy();
            self.scroll();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.header = new Header();
})();
