(function () {
    if ('modules' in $ && 'feedback' in $.modules) {
        return;
    }

    var Feedback = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('submit', '.feedback__form', self.submit);
        };

        self.submit = function(event) {
            event.preventDefault();

            var $this = $(this),
                $submit = $this.find('.formSubmit__button'),
                submitText = $submit.html();
            $this.find('div.formInput, div.formTextarea').removeClass('formErrorState');
            $submit.addClass('disabled').html('...');

            $.ajax({
                type: "POST",
                url: $this.attr("action"),
                data: $this.serialize(),
                dataType: 'json'
            }).done(function(response) {
                if(response.success) {
                    $this.find('input[type=text], textarea').val('');
                    $.modules.modal.open('#feedback');
                } else {
                    self.formErrors($this, response.errors);
                }
                $submit.removeClass('disabled').html(submitText);
            }).fail(function(jqXhr){
                self.formErrors($this, jqXhr.responseJSON.errors);
                $submit.removeClass('disabled').html(submitText);
            });
        };

        self.formErrors = function($form, errors) {
            for(var fieldName in errors) {
                if (errors.hasOwnProperty(fieldName)) {
                    var $input = $form.find('[name$="[' + fieldName + ']"]');
                    if ($input.length) {
                        $input.closest('div.formInput, div.formTextarea').addClass('formErrorState');
                        // todo: put a message somewhere. message = errors[fieldName]
                    }
                }
            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.feedback = new Feedback();
})();
