(function () {
    if ('modules' in $ && 'share' in $.modules) {
        return;
    }

    var Share = function () {
        var self = this;

        self.body = '';

        self.openPopup = function(url) {
            var width = 640,
                height = 400,
                x = (screen.width - width) / 2,
                y = (screen.height - height) / 2;
            var popup = window.open("", "_blank", "top=" + y + ",left=" + x + ",width=" + width + ",height=" + height + ",resizable=1,toolbar=0,scrollbars=1,location=0,status=0,menubars=0");
            var contents = '<!DOCTYPE html><html><head><meta http-equiv="content-type" content="text/html; charset=UTF-8" /><title>...</title></head><body style="margin:0;padding:0;"><div style="width:100%;padding:17px 0;text-align:center;background-color:#fff;color:#000;font:normal 14px/16px sans serif">...</div></body></html>';
            popup.document.write(contents);
            popup.location.href = url;
        };

        self.setupListner = function() {
            self.body.on('click', '.js-popup', function(e) {
                e.preventDefault();
                self.openPopup($(this).attr("href"));
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.share = new Share();
})();
