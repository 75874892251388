(function () {
    if ('modules' in $ && 'search' in $.modules) {
        return;
    }

    var Search = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.search');
        self.form = self.container.find('.search__form');
        self.input = self.container.find('.formInput');
        self.button = self.container.find('.search__submit');
        self.closeButton = self.container.find('.search__close'),
        self.windowWidth = 0;

        self.setupListner = function() {
            self.button.on('click', self.click);
            self.closeButton.on('click', self.close);
            self.window.on('resize', self.resize);
        };

        self.resize = function() {
            var width = self.window.width();

            if (width !== self.windowWidth) {

                self.windowWidth = width;
                self.close();

            }
        };

        self.click = function(event) {
            event.preventDefault();

            var state = self.input.css('display');

            if (state === 'block') {

                self.submit();

            } else {

                self.open();

            }
        };

        self.submit = function() {
            self.form.get(0).submit();
        };

        self.open = function() {
            var windowWidth = self.window.width(),
                containerLeft = parseInt(self.container.offset().left),
                containerWidth = windowWidth - containerLeft;

            self.container.css({
                'width': containerWidth
            })
            .addClass('js_open');
        };

        self.close = function(event) {
            if (event) {

                event.preventDefault();

            }

            self.container.attr('style', '').removeClass('js_open');
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
            self.windowWidth = self.window.width();
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.search = new Search();
})();
