(function () {
    if ('modules' in $ && 'footer' in $.modules) {
        return;
    }

    var Footer = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.footer');
        self.navigation = $('.footer__navigation');
        self.social = $('.footer__social');
        self.btn = $('.footer__btn');
        self.developer = $('.footer__developer');
        self.logo = $('.footer__logo');

        self.resizeTimer = '';

        self.setupListner = function() {
            self.window.on('resize', self.resize);
        };

        self.setup = function() {
            self.container.addClass('js_sticky');
        };

        self.stickBottom = function() {
            self.container.css({
                'display': 'block'
            });

            self.wrapperHeight = self.container.outerHeight();

            self.body.css('padding-bottom', self.wrapperHeight);
        };

        self.resize = function(event) {

            clearTimeout(self.resizeTimer);

            self.resizeTimer = setTimeout(function () {

                self.stickBottom();

            }, 250);
        };

        self.copy = function() {
            self.social.clone().addClass('mobile').insertAfter(self.navigation);
            self.btn.clone().addClass('tablet').insertBefore(self.developer);
            self.btn.clone().addClass('mobile').insertAfter(self.logo);
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.copy();
            self.setup();
            self.stickBottom();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.footer = new Footer();
})();
