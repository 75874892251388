(function () {
    if ('modules' in $ && 'map' in $.modules) {
        return;
    }

    var Map = function () {
        var self = this;

        self.body = '';
        self.container = $('.map');
        self.wrapper = $('.map__wrapper');
        self.center = '';
        self.markers = '';
        self.map = '';

        self.getData = function() {
            self.center = self.container.data('center');
            self.markers = self.container.data('markers');
        };

        self.render = function() {
            var centerLat = parseFloat(self.center.lat) || 56.950432,
                centerLong = parseFloat(self.center.long) || 24.098664;

            self.map = new google.maps.Map(self.wrapper.get(0), {
                zoom: 13,
                center: new google.maps.LatLng(centerLat, centerLong),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                mapTypeControl: false,
                zoomControl: true,
                panControl: true,
                draggable: !("ontouchend" in document)
            });
        };

        self.addMarkers = function() {
            var image = new google.maps.MarkerImage('/images/ico-pin.svg', null, null, null, new google.maps.Size(40,50));

            self.markers.map(function(item) {
                var lat = parseFloat(item.lat),
                    long = parseFloat(item.long);

                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, long),
                    icon: image,
                    map: self.map
                });
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            if (self.container.length) {

                self.importDefaults();
                self.getData();
                self.render();
                self.addMarkers();

            }
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.map = new Map();
})();
