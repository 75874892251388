(function () {
    if ('modules' in $ && 'gallery' in $.modules) {
        return;
    }

    var Gallery = function () {
        var self = this;

        self.body = '';
        self.container = $('.gallery');

        self.initModal = function() {
            self.container.magnificPopup({
                delegate: 'a',
                type: 'image',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0,1]
                },
                image: {
                    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function(item) {
                        return item.el.attr('title') + '<small>'+ self.container.attr('data-title') +'</small>';
                    }
                }
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.initModal();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.gallery = new Gallery();
})();
