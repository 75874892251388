(function () {
    if ('modules' in $ && 'serverError' in $.modules) {
        return;
    }

    var ServerError = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.header = '';
        self.footer = '';
        self.container = $('.serverError');
        self.wrapper = $('.serverError__wrapper');
        self.resizeTimer = '';

        self.setupListner = function() {
            self.window.on('resize', self.resize);
        };

        self.resize = function() {
            clearTimeout(self.resizeTimer);

            self.resizeTimer = setTimeout(function () {

                self.setup();

            }, 250);
        };

        self.setup = function() {
            var viewHeight = self.window.outerHeight() - self.header.outerHeight() - self.footer.outerHeight(),
                wrapperHeight = self.wrapper.outerHeight(),
                height = (viewHeight > wrapperHeight) ? viewHeight : 'auto';

            self.container.css({
                'height': height
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
            self.header = $.modules.header.container;
            self.footer = $.modules.footer.container;
        };

        self.init = function() {
            self.importDefaults();
            self.setup();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.serverError = new ServerError();
})();
