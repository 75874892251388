(function () {
    if ('modules' in $ && 'faq' in $.modules) {
        return;
    }

    var Faq = function () {
        var self = this;

        self.body = '';
        self.container = $('.faq');

        self.setupListner = function() {
            self.container.on('click', self.router);
        };

        self.router = function(event) {
            event.preventDefault();

            var target = $(event.target),
                container = target.closest('.faq__name');

            if (container.length) {

                self.toggle(container);

            }
        };

        self.toggle = function(element) {
            var container = element.parent();

            if (!container.hasClass('active')) {

                container.addClass('active');

            } else {

                container.removeClass('active');

            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.faq = new Faq();
})();
