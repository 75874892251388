(function () {
    if ('modules' in $ && 'dropDown' in $.modules) {
        return;
    }

    var DropDown = function () {
        var self = this;

        self.container = $('body');

        self.setupListner = function() {
            self.container.on('click focusin focusout mouseover', '.dropDown__trigger', function() {
                var target = $(this),
                    list = target.closest('.dropDown__list'),
                    initType = '';

                if (!list.hasClass('dropit')) {

                    initType = (target.hasClass('trigger_click')) ? 'click' : (Modernizr.touchevents) ? 'click' : 'mouseenter';

                    list.dropit({
                        action: initType,
                        submenuEl: 'div.dropDown__container',
                        afterLoad: function() {

                            if (initType === 'mouseenter') {

                                target.trigger('mouseenter');

                            }

                        }
                    });

                }
            });
        };

        self.init = function() {
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.dropDown = new DropDown();
})();
