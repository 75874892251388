(function () {
    if ('modules' in $ && 'payment' in $.modules) {
        return;
    }

    var Payment = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('input', '.payment__values .formInput__input', self.input);
            self.body.on('change', '.payment__values .formRadio__input', self.radio);
            self.body.on('click', '.payment__btn', self.toggle);
        };

        self.input = function() {
            var target = $(this),
                container = target.closest('.payment__values'),
                radio = container.find('.formRadio__input:checked');

            radio.prop('checked', false);
        };

        self.radio = function() {
            var target = $(this),
                container = target.closest('.payment__values'),
                input = container.find('.formInput__input');

            input.val('');
        };

        self.toggle = function(event) {
            event.preventDefault();

            var target = $(this),
                box = target.siblings('.payment__requisites');

            if (!target.hasClass('active')) {

                target.addClass('active');
                box.slideDown(200);

            } else {

                target.removeClass('active');
                box.slideUp(200);

            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.payment = new Payment();
})();
