(function () {
    if ('modules' in $ && 'cards' in $.modules) {
        return;
    }

    var Cards = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.container = $('.cards');
        self.info = $('.cards__info');
        self.btn = $('.cards__more .btn');
        self.items = $('.cards__item');
        self.first = self.items.eq(0);

        self.setupListner = function() {
            self.btn.on('click', self.more);
        };

        self.more = function(event) {
            event.preventDefault();

            var width = parseInt(100*self.first.width()/self.first.offsetParent().width());

            if (width > 30 && width < 40) {

                self.show(3);

            } else if (width > 40 && width < 50) {

                self.show(2);

            } else {

                self.show(1);

            }
        };

        self.show = function(count) {
            var visible = self.items.filter(function(index) {
                    return $(this).css('display') === 'block';
                }), // get all visible items
                visibleLength = visible.length, // get length of visible items
                total = visibleLength + count, // counting how much items should we show
                delta = total%count, // check remainder of the division
                result = count-delta, // find how many items should we finally show
                i = 0;

            for(i; i < result; i++) {

                self.items.each(function() {
                    var target = $(this);

                    if (!target.hasClass('js_show') && target.css('display') === 'none') {

                        target.addClass('js_show');

                        self.initHeight();

                        if (target.is(':last-child')) {

                            self.btn.addClass('disabled');

                        }

                        return false;

                    }
                });

            }

            self.container.addClass('js_loaded_more');
        };

        self.initHeight = function() {
            self.info.matchHeight({
                byRow: true
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.initHeight();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.cards = new Cards();
})();
