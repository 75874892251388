(function () {
    if ('modules' in $ && 'partners' in $.modules) {
        return;
    }

    var Partners = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.container = $('.partners.hasRotator');
        self.list = $('.partners__list');
        self.items = $('.partners__item');
        self.arrows = $('.partners__arrows__item');

        self.setupListner = function() {
            self.arrows.on('click', self.slide);
        };

        self.initSlider = function() {
            self.list.lightSlider({
                item: 3,
                slideMove: 1,
                slideMargin: 20,
                controls: false,
                adaptiveHeight: false,
                auto: true,
                loop: true,
                pause: 5000,
                responsive : [
                    {
                        breakpoint: 1024,
                        settings: {
                            item: 2
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            item: 1,
                            adaptiveHeight: true
                        }
                    }
                ],
                onSliderLoad: function () {
                    self.window.trigger('resize');
                    self.container.addClass('partners_js_inited');
                }
            });
        };

        self.slide = function(event) {
            event.preventDefault();

            var target = $(this);

            if (target.hasClass('prev')) {

                self.list.goToPrevSlide();

            } else {

                self.list.goToNextSlide();

            }
        };

        self.initHeight = function() {
            self.items.matchHeight({
                byRow: false
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            if (self.container.length) {

                self.importDefaults();
                self.initHeight();
                self.initSlider();
                self.setupListner();

            }
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.partners = new Partners();
})();
