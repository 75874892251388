(function () {
    if ('modules' in $ && 'burger' in $.modules) {
        return;
    }

    var Burger = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.burger = $('.burger');
        self.mobileNavigation = $('.mobileNavigation');
        self.container = $('.mobileNavigation__content');
        self.navigation = $('.navigation__list');
        self.back = $('.mobileNavigation__back');


        self.setupListner = function() {
            self.burger.on('click', function(event) {
                event.preventDefault();

                if (!$(this).hasClass('active')) {
                    self.open();
                } else {
                    self.close();
                }
            });

            self.back.on('click', self.close);

            self.window.on('resize orientationchange', function () {
                if (self.burger.css('display') === 'none' && self.burger.hasClass('active')) {
                    self.close();
                }
            });
        };

        self.setup = function() {
            self.navigation.clone().removeClass('navigation__list').addClass('mobileNavigation__list').appendTo(self.container);
        };

        self.open = function() {
            self.burger.addClass('active');
            self.mobileNavigation.addClass('active');
            self.body.addClass('mobileNavigationShow');
        };

        self.close = function(event) {
            if (event) {

                event.preventDefault();

            }

            self.burger.removeClass('active');
            self.mobileNavigation.removeClass('active');
            self.body.removeClass('mobileNavigationShow');
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setup();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.burger = new Burger();
})();
