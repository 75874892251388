(function () {
    if ('modules' in $ && 'reviews' in $.modules) {
        return;
    }

    var Reviews = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.container = $('.reviews.hasRotator');
        self.list = $('.reviews__list');
        self.items = $('.reviews__container');
        self.text = $('.reviews__text');
        self.author = $('.reviews__author');
        self.arrows = $('.reviews__arrows__item');

        self.setupListner = function() {
            self.arrows.on('click', self.slide);
        };

        self.initSlider = function() {
            self.list.lightSlider({
                item: 3,
                slideMove: 1,
                slideMargin: 5,
                controls: false,
                adaptiveHeight: false,
                responsive : [
                    {
                        breakpoint: 1024,
                        settings: {
                            item: 2
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            item: 1,
                            adaptiveHeight: true
                        }
                    }
                ],
                onSliderLoad: function () {
                    self.window.trigger('resize');
                    self.sliderArrows();
                    self.container.addClass('reviews_js_inited');
                },
                onAfterSlide: function () {
                    self.sliderArrows();
                }
            });
        };

        self.slide = function(event) {
            event.preventDefault();

            var target = $(this);

            if (target.hasClass('prev')) {

                self.list.goToPrevSlide();

            } else {

                self.list.goToNextSlide();

            }
        };

        self.sliderArrows = function () {
            var items = self.container.find('.lSPager li'),
                total = items.length,
                active = self.container.find('.lSPager li.active').index() + 1;
            if (active === 1) {

                self.container.removeClass('last');
                self.container.addClass('first');

            } else if (active === total) {

                self.container.removeClass('first');
                self.container.addClass('last');

            } else {

                if (self.container.hasClass('first') || self.container.hasClass('last')) {

                    self.container.removeClass('first last');

                }

            }
        };

        self.initHeight = function() {
            self.author.matchHeight({
                byRow: false
            });

            self.text.matchHeight({
                byRow: false
            });

            self.items.matchHeight({
                byRow: false
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.initHeight();

            if (self.container.length) {

                self.initSlider();
                self.setupListner();

            }
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.reviews = new Reviews();
})();
