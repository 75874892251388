(function () {
    if ('modules' in $ && 'subNavigation' in $.modules) {
        return;
    }

    var SubNavigation = function () {
        var self = this;

        self.body = $('body');

        self.setupListner = function() {
            self.body.on('click focusin focusout mouseover', '.subNavigation__trigger', self.initDropdown);
        };

        self.initDropdown = function() {
            var target = $(this),
                list = target.closest('.subNavigation__list'),
                initType = '';

            if (!list.hasClass('dropit')) {

                // initType = (target.hasClass('trigger_click')) ? 'click' : (Modernizr.touchevents) ? 'click' : 'mouseenter'; // in this case subnavigation trigger link not working in mobile state
                initType = (target.hasClass('trigger_click')) ? 'click' : 'mouseenter'; // in this case subnavigation do not open on touch devices in desktop state

                list.dropit({
                    action: initType,
                    submenuEl: 'div.subNavigation__container',
                    afterLoad: function() {

                        if (initType === 'mouseenter') {

                            target.trigger('mouseenter');

                        }

                    }
                });
            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.subNavigation = new SubNavigation();
})();
