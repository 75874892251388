(function () {
    if ('modules' in $ && 'formRadio' in $.modules) {
        return;
    }

    var FormRadio = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('click', '.formRadio .disabled', function(event) {
                event.preventDefault();
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formRadio = new FormRadio();
})();
