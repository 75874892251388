(function () {
    if ('modules' in $ && 'hero' in $.modules) {
        return;
    }

    var Hero = function () {
        var self = this;

        self.body = '';
        self.container = $('.hero');
        self.list = $('.hero__list');
        self.arrows = $('.hero__arrows__item');

        self.setupListner = function() {
            self.arrows.on('click', self.slide);
        };

        self.initSlider = function() {
            self.list.lightSlider({
                item: 1,
                slideMove: 1,
                slideMargin: 0,
                auto: true,
                loop: true,
                pause: 6000,
                controls: false,
                adaptiveHeight: true,
                onSliderLoad: function () {
                    self.list.removeClass('list_hidden');
                    self.container.addClass('hero_js_inited');
                }
            });
        };

        self.slide = function(event) {
            event.preventDefault();

            var target = $(this);

            if (target.hasClass('prev')) {

                self.list.goToPrevSlide();

            } else {

                self.list.goToNextSlide();

            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.initSlider();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.hero = new Hero();
})();
