(function () {
    if ('modules' in $ && 'formInput' in $.modules) {
        return;
    }

    var FormInput = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('focus', '.formInput:not(.formInput_disabled) input', self.focusIn);
            self.body.on('click', '.formInput:not(.formInput_disabled) label', self.focusLabel);
            self.body.on('blur', '.formInput:not(.formInput_disabled) input', self.focusOut);
            self.body.on('focus', '.formInput.formInput_mask:not(.formInput_disabled) input', self.mask);
            self.body.on('click focus blur input change', '.formInput.formInput_disabled', self.disabled);
        };

        self.focusIn = function(event) {
            $(this).closest('.formInput').addClass('active');
        };

        self.focusLabel = function(event) {
            $(this).closest('.formInput').find('input').focus();
        };

        self.focusOut = function(event) {
            if ($(this).val() === '') {
                $(this).closest('.formInput').removeClass('active');
            }
        };

        self.mask = function() {
            var target = $(this),
                patternNumber = '';

            if (!target.hasClass('mask_js_inited')) {

                patternNumber = target.attr('data-pattern-number');
                target.mask(patternNumber)
                .addClass('mask_js_inited');

            }
        };

        self.disabled = function(event) {
            event.preventDefault();
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formInput = new FormInput();
})();
